import React from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { IProduct } from 'types/product';
import Button from 'ui-components/Button';
import BinItem from './BinItem';

interface IBinItemsListProps {
  items?: IProduct[];
  binId?: string;
  backUrl?: string | null;
  filterBinned?: boolean;
  filterUnbinned?: boolean;
  onSelect?: (item) => void;
  selectedItems?: string[];
}

const BinItemsList: React.FC<IBinItemsListProps> = ({
  items,
  binId,
  backUrl = '/dashboard/bins',
  filterBinned = false,
  filterUnbinned = false,
  onSelect = console.info,
  selectedItems = [],
}) => {
  const binnedItems = items?.filter((item) => item?.bin?.status) || [];
  const navigate = useNavigate();

  function handleBack() {
    if (backUrl) {
      navigate(backUrl);
    }
  }

  return (
    <div className="w-full space-y-8">
      {backUrl ? (
        <header className="flex text-xl font-bold border-b">
          <Button
            size="custom"
            className="w-8 h-8 mr-1"
            variant="clear"
            color="light-gray"
            onClick={handleBack}
          >
            <ChevronLeft size={26} />
          </Button>
          <h2 className="uppercase text-mantis-500">Bin {binId}</h2>
          <span className="ml-auto text-gray-400">
            {!filterUnbinned ? selectedItems?.length + binnedItems.length + '/'
              + items?.length : selectedItems?.length + '/' + binnedItems.length}
          </span>
        </header>
      ) : null}
      <div className='list-wrapper'>
        <ul className="grid gap-8">
          {items
            ?.filter((item) => {
              if (filterBinned) {
                return !item.bin?.status;
              } else if (filterUnbinned) {
                return item.bin?.status
              }

              return true;
            })
            .map((item) => (
              <li key={item._id} onClick={() => onSelect({ id: item._id, name: item.name, binned: item.bin?.status })}>
                <BinItem
                  onSelect={onSelect} {...item}
                  binned={item.bin?.status}
                  id={item._id}
                  selected={selectedItems.indexOf(item._id) !== -1}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default BinItemsList;
