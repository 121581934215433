import classNames from "classnames";
import React from "react";

export type TSpinnerColor = "mantis" | "white";
interface ISpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: TSpinnerColor | "custom";
  fullScreen?: boolean;
}

const Spinner: React.FC<ISpinnerProps> = ({
  className,
  color = "mantis",
  fullScreen,
  ...props
}) => {
  return (
    <span
      className={classNames(
        className,
        "inline-flex items-center justify-center",
        {
          "fixed top-0 left-0 w-full h-full": fullScreen,
        }
      )}
      {...props}
    >
      <span
        className={classNames(
          "relative animate-spin",
          "w-[1em] h-[1em] rounded-full",
          "border-[0.1em]",
          {
            "border-white/30 border-r-white": color === "white",
            "border-mantis-500/30 border-r-mantis-500": color === "mantis",
          }
        )}
      />
    </span>
  );
};
export default Spinner;

// .loader,
// .loader:after {
//   border-radius: 50%;
//   width: 10em;
//   height: 10em;
// }
// .loader {
//   margin: 60px auto;
//   font-size: 10px;
//   position: relative;
//   text-indent: -9999em;
//   border-top: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-right: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-left: 1.1em solid #ffffff;
//   -webkit-transform: translateZ(0);
//   -ms-transform: translateZ(0);
//   transform: translateZ(0);
//   -webkit-animation: load8 1.1s infinite linear;
//   animation: load8 1.1s infinite linear;
// }
// @-webkit-keyframes load8 {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }
// @keyframes load8 {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }
