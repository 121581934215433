import BinItemsList from 'components/BinItemsList';
import BinnedItemsTab from 'components/BinnedItemsTab';
import useBinData from 'hooks/data/useBinData';
import { useAppLayoutOutletContext } from 'layouts/AppLayout';
import React from 'react';
import { Plus } from 'react-feather';
import { BINSTATUS } from 'appConstants';
import { useNavigate, useParams } from 'react-router-dom';
import ModalAlert from 'ui-components/ModalAlert';
import ClearBinModal from 'components/ClearBinModal';

interface IBinDetailsPageProps { }

const BinDetailsPage: React.FC<IBinDetailsPageProps> = () => {
  const { id, activeTab = 'unbinned' } = useParams<{
    id: string;
    activeTab: 'unbinned' | 'all';
  }>();
  const { setState: setHeaderAndFooter } = useAppLayoutOutletContext();
  const { bin, updateItemsToBinList, itemsToBin, binErrMsg, binStatus, clearBin } =
    useBinData(id || '');
  const navigate = useNavigate();

  function goBack() {
    navigate('../../');
  }

  function handleBinClear(redirectUrl: string) {
    const binId = bin?._id;
    if (id) { 
      clearBin(`${binId}`, redirectUrl);
    }
  }

  React.useEffect(() => {
    const title = (
      <span className="block font-bold text-center text-gray-600">
        Bin #{id} / Order #{bin?.orderNumber}
      </span>
    );
    let footer = (
      <div className="flex flex-col items-center mt-3 space-y-3">
        <button
          onClick={() => navigate(`../items/${id}`)}
          className="flex items-center justify-center w-16 h-16 text-2xl text-center text-white bg-blue-500 rounded-full"
        >
          <Plus size={40} />
        </button>
        <span className="text-sm font-bold text-gray-600">
          Add Multiple Items to Bin
        </span>
      </div>
    );
    setHeaderAndFooter({
      title,
      footer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeaderAndFooter, itemsToBin.length, bin]);

  React.useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
    }
    return () => {
      didCancel = true;
    };
  }, [activeTab]);

  function handleSelectItem(e: React.MouseEvent<HTMLDivElement>) {
    const dataSet = e.currentTarget?.dataset;
    const id = dataSet?.id as string;

    if (id) {
      updateItemsToBinList(id);
    }
  }

  return (
    <div>
      <BinnedItemsTab
        options={[
          {
            label: 'All Items',
            value: 'all',
          },
          {
            label: 'Unbinned',
            value: 'unbinned',
          },
        ]}
        binId={id || ''}
        selected={activeTab}
      />
      <BinItemsList
        binId={id}
        items={bin?.order.items}
        filterBinned={activeTab === 'unbinned'}
        selectedItems={itemsToBin}
        onSelect={handleSelectItem}
      />

      <ClearBinModal
        open={binStatus === BINSTATUS.CANCELLED}
        type="error"
        title="Error: Order Cancelled"
        message={`The order occupying this BIN: ${bin?.name} has been cancelled. 
          Please remove any product from the bin before continuing.`}
        onClose={goBack}
        onOk={() => handleBinClear('../../')}
        closeBtnLabel="Close"
        okLabel="Clear BIN"
/>
      <ModalAlert
        open={!!binErrMsg}
        type="error"
        title="Error"
        message={binErrMsg}
        onClose={goBack}
      />
    </div>
  );
};
export default BinDetailsPage;
