import HamburgerMenu from 'components/HamburgerMenu';
import React, { Suspense } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Spinner from 'ui-components/Spinner';

const AppLayout: React.FC = () => {
  const [state, setState] = React.useState<{
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    footer?: React.ReactNode;
    iconButton?: React.ReactNode;
  }>({ title: null, subTitle: null, footer: null, iconButton: null });

  function setTitle(title: React.ReactNode) {
    setState({ ...state, title });
  }

  function setSubTitle(subTitle: React.ReactNode) {
    setState({ ...state, subTitle });
  }

  function setFooter(footer: React.ReactNode) {
    setState({ ...state, footer });
  }

  function setIconButton(iconButton: React.ReactNode) {
    setState({ ...state, iconButton });
  }

  return (
    <div className="flex flex-col w-full h-full px-4 py-6">
      <header className="pb-3 mb-6 border-b-2">
        <div className="flex items-center">
          <div className="flex-none w-12">
            {state.iconButton || <HamburgerMenu />}
          </div>
          <div className="flex-grow px-3">
            <h1>{state.title}</h1>
          </div>
          <div className="flex-none w-12"></div>
        </div>
        <h2 className="text-sm font-bold text-center text-gray-600">
          {state.subTitle}
        </h2>
      </header>
      <Suspense fallback={<Spinner className="w-full h-full text-6xl" />}>
        <Outlet
          context={{
            ...state,
            setTitle,
            setSubTitle,
            setFooter,
            setIconButton,
            setState,
          }}
        />
      </Suspense>
      {!!state.footer && (
        <footer style={{ backgroundColor: '#fff' }} className="sticky bottom-0 w-full pt-2 mt-auto border-t-2">
          {state.footer}
        </footer>
      )}
    </div>
  );
};
export default AppLayout;

interface IAppLayoutOutletContext {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  footer?: React.ReactNode;
  iconButton?: React.ReactNode;
  setTitle: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setSubTitle: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setFooter: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setIconButton: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setState: React.Dispatch<
    React.SetStateAction<{
      title?: React.ReactNode;
      subTitle?: React.ReactNode;
      footer?: React.ReactNode;
      iconButton?: React.ReactNode;
    }>
  >;
}
export function useAppLayoutOutletContext() {
  return useOutletContext<IAppLayoutOutletContext>();
}
