import React from 'react';
import useBatchData from 'hooks/data/useBatchData';
import { useNavigate, useParams } from 'react-router-dom';
import ModalAlert from 'ui-components/ModalAlert';


interface IBatchDetailsPageProps { };

const BatchDetailsPage: React.FC<IBatchDetailsPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { batch, completeBatch, batchErrMsg } = useBatchData(id || '');
  const navigate = useNavigate();

  function goBack() {
    navigate('../');
  }

  React.useEffect(() => {
    console.info('id... _>', id);
    handleBatchComplete('../');
  }, []);

  function handleBatchComplete(redirectUrl: string) {
    if (id!==undefined) {
      completeBatch(`${id}`, redirectUrl);
    }
  }

  return (
    <div>
      <ModalAlert
        open={!!batchErrMsg}
        type="error"
        title="Error"
        message={batchErrMsg}
        onClose={goBack}
        />
    </div>
  )
};

export default BatchDetailsPage;

