import classNames from  'classnames';
import React from 'react';
import Button from 'ui-components/Button';


interface IBinProps {
  id?: string;
  name?: string;
  onOk?: () => void;
}


const Bin: React.FC<IBinProps> = ({
  id,
  name,
  onOk,
}) => {
  return (
    <div
      data-id={id}
      className={classNames('flex space-x-3 items-center')}
    >
      <span className="flex-grow">BIN: {name}</span>
        <div>
          <Button
            size="s"
            color="custom"
            className={classNames('w-full text-white', {
              'bg-rose-600': true,
            })}
            onClick={onOk}
          >
            {'Clear'}
          </Button>
        </div>
    </div>
  );
};


export default Bin;
