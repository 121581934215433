import React from 'react';

export function useClearableState<TState = any>(
  initialState?: TState
): [TState | undefined, (v: TState) => void, () => void] {
  const [state, setState] = React.useState<TState | undefined>(initialState);

  const clear = React.useCallback(
    (): void => setState(initialState),
    [initialState]
  );

  return [state, setState, clear];
}
