import { API_URL, DEFAULT_ROUTE } from 'appConstants';
import PrivatePage from 'components/PrivatePage';
import { useAuth } from 'contexts/AuthContext';
import LoginPage from 'pages/LoginPage';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  CachePolicies,
  IncomingOptions,
  Provider as UseFetchProvider,
} from 'use-http';
import DashboardRoutes from './DashboardRoutes';

const options: IncomingOptions = {
  suspense: true,
  cachePolicy: CachePolicies.NO_CACHE,
};
const AppRoute: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return (
    <UseFetchProvider
      url={API_URL}
      options={{
        ...options,
        headers: {
          Authorization:
            (isAuthenticated &&
              `Bearer ${localStorage.getItem('authToken')}`) ||
            '',
        },
      }}
    >
      <Routes>
        <Route>
          <Route path="/" element={<Navigate to={DEFAULT_ROUTE} />} />
          <Route path="login" element={<LoginPage />} />
          <Route
            path="dashboard/*"
            element={
              <PrivatePage>
                <DashboardRoutes />
              </PrivatePage>
            }
          />
        </Route>
      </Routes>
    </UseFetchProvider>
  );
};
export default AppRoute;
