import classNames from 'classnames';
import React from 'react';
import { AlertOctagon, CheckCircle } from 'react-feather';
import Button from './Button';
import Modal from './Modal';

interface ModalAlertProps {
  type?: 'success' | 'error' | 'warning' | 'info';
  title?: React.ReactNode;
  message?: React.ReactNode;
  open?: boolean;
  closeBtnLabel?: React.ReactNode;
  onClose?: (e?: any) => void;
}

const ModalAlert: React.FC<ModalAlertProps> = ({
  type = 'info',
  title,
  message,
  open = false,
  onClose,
  
  closeBtnLabel = 'Close',
}) => {
  const Icon = type === 'success' ? CheckCircle : AlertOctagon;
  return (
    <Modal paddingClassName="p-0" open={open} onClose={onClose} footer={null}>
      <div className="px-8 pb-8">
        <div className="relative flex flex-col justify-center pt-10 text-center">
          <div className="mx-auto mb-4">
            <Icon
              className={classNames('stroke-[1px]', {
                'text-emerald-500': type === 'success',
                'text-rose-600': type === 'error',
              })}
              height="80"
              width="80"
            />
          </div>
          <div className={'text-2xl font-semibold'}>{title}</div>
          <div className="my-6 text-lg">{message}</div>
          <div className="flex-none w-9/12 mx-auto">
            <Button
              color="custom"
              className={classNames('w-full text-white', {
                'bg-emerald-600': type === 'success',
                'bg-rose-600': type === 'error',
              })}
              onClick={onClose}
            >
              {closeBtnLabel}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ModalAlert;
