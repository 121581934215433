import React, { useCallback } from 'react';
import { IUser } from 'types/user';
import jwtDecode from 'jwt-decode';
import { API_URL } from 'appConstants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IHttpResponse } from 'types/httpResponse';

interface ICredentials {
  email: string;
  password: string;
}

interface IAuthContext {
  user?: IUser;
  isAuthenticated?: boolean;
  logIn: (credentials: ICredentials) => any;
  logOut: () => void;
}

const AuthContext = React.createContext<IAuthContext>({
  user: undefined,
  isAuthenticated: undefined,
  logIn: () => {},
  logOut: () => {},
});

function isJWTExpired(jwtExpiry: number): boolean {
  let currentDate = new Date();
  // JWT exp is in seconds
  if (jwtExpiry * 1000 < currentDate.getTime()) {
    return true;
  }
  return false;
}

export const AuthProvider: React.FC = (props) => {
  const [user, setUser] = React.useState<IUser | undefined>(undefined);
  const [isAuthTokenValid, setIsAuthTokenValid] = React.useState<
    boolean | undefined
  >(undefined);
  const navigate = useNavigate();

  const fetchUserDetailsAndSaveUserToState = useCallback(
    async (token: string): Promise<void> => {
      try {
        const url = `${API_URL}/auth/me`;
        const response = await axios.get<IHttpResponse<IUser>>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const user = response.data.data;
        setUser(user);
      } catch (error) {
        console.error({ error });
        setIsAuthTokenValid(false);
      }
    },
    []
  );

  React.useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      let decodedToken = jwtDecode<{ exp: number } & IUser>(token);
      const isExpired = isJWTExpired(decodedToken.exp);
      setIsAuthTokenValid(!isExpired);
      if (!isExpired) {
        fetchUserDetailsAndSaveUserToState(token);
      }
    } else {
      setIsAuthTokenValid(false);
    }
  }, []);

  const logIn = async (credentials: ICredentials) => {
    try {
      const url = `${API_URL}/auth/login`;
      const res = await axios.post<IHttpResponse<IUser & { token: string }>>(
        url,
        credentials
      );
      const isAuthenticated = res.status === 200 && !!res.data.data;
      if (isAuthenticated) {
        const token = res.data.data!.token;
        const user = res.data.data!;
        localStorage.setItem('authToken', token);
        setUser(user);
        setIsAuthTokenValid(true);
      }
      return { success: isAuthenticated };
    } catch (error) {
      return error;
    }
  };

  const logOut = async () => {
    localStorage.removeItem('authToken');
    setUser(undefined);
    setIsAuthTokenValid(false);
    navigate('/login');
  };

  return (
    <AuthContext.Provider
      {...props}
      value={{
        user,
        isAuthenticated: isAuthTokenValid,
        logIn,
        logOut,
      }}
    />
  );
};

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
