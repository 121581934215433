import BinScanner from 'components/BinScanner';
import ScanAreaIcon from 'customIcons/ScanAreaIcon';
import { useToggle } from 'hooks/util/useToggle';
import { useAppLayoutOutletContext } from 'layouts/AppLayout';
import React, { useRef } from 'react';
import { Package } from 'react-feather';

type BinsPageProps = {};

const BinsPage: React.FC<BinsPageProps> = () => {
  const { setState: setAppLayoutState } = useAppLayoutOutletContext();
  const [isScanning, , showScanning] = useToggle();
  const binInputRef = useRef();

  React.useEffect(() => {
    const footer = (
      <div className="grid mt-2 place-items-center">
        <button
          onClick={() => {
            showScanning();
            setTimeout(() => (binInputRef.current as any)?.focus(), 150);
          }}
          className="flex flex-col items-center p-1 space-y-2 rounded-lg active:ring-mantis-300 active:ring-offset-mantis-100 active:outline-none active:ring-2 active:ring-offset-2"
        >
          <div className="relative text-mantis-500">
            <Package className="fill-current stroke-mantis-100" size="72" />
            <span className="absolute inset-0 flex items-center justify-center text-3xl text-white">
              <ScanAreaIcon />
            </span>
          </div>
          <p className="font-bold text-gray-600">Scan Bin QR Code</p>
        </button>
      </div>
    );
    setAppLayoutState({
      footer,
      subTitle: 'No Bin is Active - Ready to Scan',
    });
  }, [setAppLayoutState, showScanning]);

  return isScanning ? (
    <BinScanner isScanning={true} binInputRef={binInputRef} />
  ) : (
    <BinScanner isScanning={false} binInputRef={binInputRef} />
  );
};

export default BinsPage;
