import { useToggle } from 'hooks/util/useToggle';
import React from 'react';
import { Eye, EyeOff } from 'react-feather';
import Input from './Input';

interface IPasswordInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const PasswordInput: React.FC<IPasswordInputProps> = ({ ...props }) => {
  const [showPassword, toggle] = useToggle();
  return (
    <span className="relative">
      <Input {...props} type={(showPassword && 'text') || 'password'}></Input>
      <button
        type="button"
        onClick={toggle}
        className="absolute top-0 right-0 h-full px-2 text-gray-400"
      >
        {(showPassword && <EyeOff />) || <Eye />}
      </button>
    </span>
  );
};
export default PasswordInput;
