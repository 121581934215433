import BatchesPage from 'pages/BatchesPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BatchCompletePage from 'pages/BatchCompletePage';

interface IBatchesRoutesProps {}

const BatchesRoutes: React.FC<IBatchesRoutesProps> = () => {
  return (
    <Routes>
      <Route index element={<BatchesPage />} />
      <Route path=":id" element={<BatchCompletePage />}/>
    </Routes>
  );
};
export default BatchesRoutes;
