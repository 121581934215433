import BinItemsList from 'components/BinItemsList';
import useBinData from 'hooks/data/useBinData';
import { useClearableState } from 'hooks/util/useClearableState';
import { useStateWithInputHandler } from 'hooks/util/useStateWithInputHandler';
import useSuccessErrorModal from 'hooks/util/useSuccessErrorModal';
import { useToggle } from 'hooks/util/useToggle';
import { useAppLayoutOutletContext } from 'layouts/AppLayout';
import React, { useRef, useState } from 'react';
import { Package } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import { IHttpResponse } from 'types/httpResponse';
import { IProduct } from 'types/product';
import Button from 'ui-components/Button';
import Input from 'ui-components/Input';
import useFetch from 'use-http';

const BinItemsPage: React.FC<any> = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const { setState: setLayoutProps } = useAppLayoutOutletContext();
  const [manualEntry, toggleManualEntry, , resetManualEntry] = useToggle();
  const [itemNumber, handleChange, setInput] = useStateWithInputHandler('');
  const [item, setItem, clearItem] = useClearableState<IProduct>();
  const { get, patch, loading } = useFetch();
  const { bin, updateItemsToBinList, itemsToBin, addItemToBin, binErrMsg } =
    useBinData(id || '');
  const navigate = useNavigate();
  const binInputRef = useRef();
  const { SuccessErrModal, setErrMsg, setSuccessMsg } = useSuccessErrorModal({
    onSuccessClose: () => {
      resetState();
      navigate('/dashboard/bins');
    },
    onErrorClose: () => resetState(),
  });
  const resetState = React.useCallback(() => {
    resetManualEntry();
    clearItem();
    setInput('');
    setBinInput('');
    (binInputRef.current as any)?.focus();
  }, [clearItem, resetManualEntry, setInput]);
  const [binInput, setBinInput] = useState('');
  const footer = null;

  function handleSelectItem(e: React.MouseEvent<HTMLDivElement>) {
    const dataSet = e.currentTarget.dataset;
    const id = dataSet.id as string;
    updateItemsToBinList(id);
  }

  async function handleScan(value: string) {
    if (value) {
      await handleItemNumberPostEntry(value);
      resetState();
    }
  }

  async function handleItemNumberPostEntry(itemNumber: string) {
    const url = `/items/order-item-id/${itemNumber}`;
    const itemRes: IHttpResponse<IProduct> = await get(url);

    if (itemRes.error) {
      setErrMsg(itemRes.error.message);

      return;
    }

    const item = itemRes.data;

    if (item.bin?.status) {
      setErrMsg('Item already binned');

      return;
    }

    setItem(item);
  }

  React.useEffect(() => {
    const title = (
      <span className="block font-bold text-center text-gray-600">
        Bin #{id} / Order #{bin?.orderNumber}
      </span>
    );

    setLayoutProps({
      title,
      footer,
      iconButton: (
        <Button onClick={() => navigate('/dashboard/bins')}>
          <Package />
        </Button>
      ),
    });

    setTimeout(() => (binInputRef.current as any)?.focus(), 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLayoutProps, itemsToBin.length, bin]);

  React.useEffect(() => {
    if (item) {
      addItemToBin(item);
      resetState();
    }
  }, [item, addItemToBin, resetState]);

  React.useEffect(() => {
    if (bin?.order?.items) {
      const unbinned = bin.order.items.filter(item => !item?.bin?.status);

      if (unbinned.length === 0) {
        setSuccessMsg('There are no remaining items');
      }
    }
  }, [bin]);

  return (
    <div>
      <Input
        name="qr-scanner-input"
        ref={binInputRef}
        value={binInput}
        style={{ textAlign: 'center', marginBottom: '1em' }}
        autoFocus={true}
        autoComplete="off"
        autoCorrect="off"
        onChange={(ev) => setBinInput(ev.target.value)}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            const binNumber = String(ev?.currentTarget.value).trim() || null;

            if (binNumber) {
              handleScan(binNumber);
            } else {
              setErrMsg(
                `Something went wrong. Input is: ${ev?.currentTarget.value}`
              );
            }
          }
        }}
      />
      <BinItemsList
        binId={id}
        items={bin?.order.items}
        filterBinned={true}
        selectedItems={itemsToBin}
        onSelect={handleSelectItem}
        backUrl={null}
      />
      {SuccessErrModal}
    </div>
  );
};

export default BinItemsPage;
