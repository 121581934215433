import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

interface IBinnedItemsTabProps {
  options?: Array<{ label: string; value: string }>;
  selected?: string;
  binId: string;
}

const BinnedItemsTab: React.FC<IBinnedItemsTabProps> = ({
  options,
  selected,
  binId,
}) => {
  return (
    <div className="flex items-center justify-center mb-4 -mt-3">
      <div className="flex bg-gray-100 rounded-xl">
        {options?.map((option) => (
          <Link
            to={`../${binId}/${option.value}`}
            className={classNames('px-5 py-2 font-bold', {
              'text-mantis-500 rounded-xl bg-mantis-100 bg-opacity-50':
                selected === option.value,
            })}
            key={option.value}
          >
            {option.label}
          </Link>
        ))}
      </div>
    </div>
  );
};
export default BinnedItemsTab;
