import classNames from 'classnames';
import React from 'react';
import Spinner, { TSpinnerColor } from './Spinner';

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: 'clear' | 'outline';
  color?: 'mantis' | 'light-gray' | 'red' | 'custom';
  size?: 'md' | 'lg' | 's' | 'custom';
  loading?: boolean;
  spinnerColor?: TSpinnerColor;
  block?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  color = 'mantis',
  size = 'md',
  children,
  className,
  loading,
  spinnerColor = 'white',
  block,
  variant,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        'y-2 transition items-center justify-center ease-in duration-200 text-center font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2',
        {
          'inline-flex': !block,
          'flex w-full': block,
          'bg-mantis-500 hover:bg-mantis-600 focus:ring-mantis-300 focus:ring-offset-mantis-100 text-white':
            color === 'mantis' && variant !== 'clear',
          'bg-gray-300 hover:bg-gray-400 focus:ring-gray-200 focus:ring-offset-gray-100':
            color === 'light-gray',
          'rounded-lg py-3 px-4 text-base': size !== 'custom' && size === 'md',
          'rounded-lg py-2 px-3 text-base': size !== 'custom' && size === 's',
          'disabled:opacity-75': props.disabled,
          'bg-transparent bg-none': variant === 'clear',
          'text-mantis-500 hover:bg-mantis-600/10 focus:ring-mantis-300 focus:ring-offset-mantis-100':
            variant === 'clear' && color === 'mantis',
          'text-gray-500 hover:bg-gray-500/10 focus:ring-gray-200 focus:ring-offset-gray-100':
            variant === 'clear' && color === 'light-gray',
          'text-rose-500 hover:bg-rose-500/10 focus:ring-rose-200 focus:ring-offset-rose-100':
            variant === 'clear' && color === 'red',
        },
        className
      )}
    >
      <span>{children}</span>
      {loading && <Spinner className="self-center ml-2" color={spinnerColor} />}
    </button>
  );
};
export default Button;
