import React from 'react';

import * as ReactDom from 'react-dom';

interface PortalProps {
  target?: string;
  className?: string;
}

const Portal: React.FunctionComponent<PortalProps> = ({
  children,
  target = '#portal-container',
  className = '',
}) => {
  const [ref, setRef] = React.useState<Element | null>(null);

  React.useEffect(() => {
    const el: HTMLElement | null = document.createElement('div');
    if (el) {
      if (className) {
        el.classList.add(className);
      }

      if (target) {
        const targetEl = document.querySelector(target);
        targetEl?.appendChild(el);
      } else {
        document.body.appendChild(el);
      }
      setRef(el);
    }
    return () => {
      if (el) {
        if (target) {
          const targetEl = document.querySelector(target);
          const isDescendant = (parent: HTMLElement, child: HTMLElement) => {
            let node = child.parentNode;
            while (node !== null) {
              if (node === parent) {
                return true;
              }
              node = node.parentNode;
            }
            return false;
          };
          if (isDescendant(targetEl as HTMLElement, el)) {
            targetEl?.removeChild?.(el);
          }
        } else {
          document.body.removeChild?.(el);
        }
        setRef(null);
      }
    };
  }, [target, className]);

  if (!ref) {
    return null;
  }
  return ReactDom.createPortal(children, ref);
};
export default Portal;
