import { DEFAULT_ROUTE } from 'appConstants';
import { useAuth } from 'contexts/AuthContext';
import { useStateWithInputHandler } from 'hooks/util/useStateWithInputHandler';
import { useToggle } from 'hooks/util/useToggle';
import LoginPageSkeleton from 'page-skeletons/LoginPageSkeleton';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from 'ui-components/Button';
import Input from 'ui-components/Input';
import PasswordInput from 'ui-components/PasswordInput';
import toast from 'react-hot-toast';

interface ILoginPageProps {}

const LoginPage: React.FC<ILoginPageProps> = () => {
  const { logIn, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // redirect user to home page if already logged in
  React.useEffect(() => {
    if (isAuthenticated) {
      const to = (location.state as any)?.from || DEFAULT_ROUTE;
      navigate(to);
    }
  }, [isAuthenticated]);

  const [loading, toggleLoading] = useToggle();
  const [{ email, password }, handleInputChange] = useStateWithInputHandler({
    email: '',
    password: '',
  });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    toggleLoading();
    const res = await logIn({ email, password });
    toggleLoading();
    if (!res.success) {
      toast.error(res.message);
    }
  }

  return (
    <LoginPageSkeleton
      headingSection={
        <div className="space-y-4">
          <h1 className="mb-8 font-semibold text-center text-gray-400 uppercase">
            Qualtry Mobile OMS
          </h1>
          <h2 className="text-3xl font-bold font-secondary">Sign In</h2>
          <p className="text-gray-400">Hi there! Nice to see you again.</p>
        </div>
      }
      loginFormSection={
        <form onSubmit={handleSubmit} className="grid gap-8">
          <Input
            name="email"
            value={email}
            type="email"
            label="Email"
            onChange={handleInputChange}
          />
          <PasswordInput
            name="password"
            value={password}
            type="password"
            label="Password"
            onChange={handleInputChange}
          />

          <Button
            type="submit"
            className="shadow-lg"
            disabled={loading}
            loading={loading}
          >
            Sign In
          </Button>
        </form>
      }
      footerSection={
        <div className="flex justify-between text-sm">
          <Link to="/forgot-password" className="font-medium text-gray-400">
            Forgot Password?
          </Link>

          <Link to="/sign-up" className="font-semibold text-mantis-500">
            Request Access
          </Link>
        </div>
      }
    />
  );
};
export default LoginPage;
