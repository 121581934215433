import { useAuth } from 'contexts/AuthContext';
import React from 'react';
import { Settings, X } from 'react-feather';
import { NavLink } from 'react-router-dom';
import Button from 'ui-components/Button';
import MenuAvatarSection from './MenuAvatarSection';
import SidebarLinkItem from './SidebarLinkItem';

interface ISidebarMenuProps {
  links: {
    icon?: React.ReactNode;
    label: string;
    to?: string;
    onClick?: () => void;
  }[];
  onClose?: () => void;
}

const SidebarMenu: React.FC<ISidebarMenuProps> = ({ links, onClose }) => {
  const { user } = useAuth();
  return (
    <div className="flex flex-col w-full h-screen py-5 overflow-y-auto">
      <section>
        <Button onClick={onClose} variant="clear" color="red">
          <X />
        </Button>
      </section>
      <header className="py-8">
        {!!user && <MenuAvatarSection user={user} />}
      </header>
      <nav className="pt-8">
        {links.map((link, ix) => {
          if (link.to) {
            return (
              <NavLink
                onClick={onClose}
                className="block w-full"
                key={link.to}
                to={link.to}
              >
                {({ isActive }) => (
                  <SidebarLinkItem isActive={isActive} {...link} />
                )}
              </NavLink>
            );
          } else {
            return <SidebarLinkItem key={ix} {...link} />;
          }
        })}
      </nav>
      <footer className="sticky bottom-0 flex pb-8 mt-auto">
        <NavLink
          onClick={onClose}
          to="/dashboard/settings"
          className="block w-full"
        >
          {({ isActive }) => (
            <SidebarLinkItem
              isActive={isActive}
              icon={<Settings />}
              label="Settings"
              hideChevron
            />
          )}
        </NavLink>
      </footer>
    </div>
  );
};
export default SidebarMenu;
