import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { IBatch } from 'types/batch';
import { IHttpResponse } from 'types/httpResponse';
import useFetch from 'use-http';


export default function useBatchData(id: string) {
  const [batch, setBatch] = useState<IBatch>();
  const [batchErrMsg, setBatchErrMsg] = useState<string>('');

  const { get, patch } = useFetch();
  const navigate = useNavigate();


  const fetchBatch = useCallback(
    async (id) => {
      const url = `/batches/name/${id}`;
      const batchRes: IHttpResponse<IBatch> = await get(url);
      if (batchRes.error) {
        setBatchErrMsg(batchRes.error.message);
        return;
      }
      const batch = batchRes.data;
      setBatch(batch);
    },
    [get]
  );

  useEffect(() => {
    if (id) {
      // api to fetch batch detail
      fetchBatch(id);
    }
  }, [fetchBatch, id]);

  async function completeBatch(id: string, redirectUrl: string) {
    const url = `/batches/${id}/complete`;
    const res: IHttpResponse<boolean> = await patch(url);
    const isComplete = res?.data;

    if (res.error) {
      toast.error(res.error.message);
      navigate(redirectUrl);
      return;
    }

    if (isComplete) {
      toast.success('The Batch is marked as complete !');
      navigate(redirectUrl);
      return;
    }
  }

  return {
    batch,
    batchErrMsg,
    fetchBatch,
    completeBatch,
  }
}