import React from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { IBin } from 'types/bin';
import Button from 'ui-components/Button';
import Bin from './Bin';


interface ICancelledBinListProps {
  bins?: IBin[];
  backUrl?: string | null;
  selectedBins?: string[];
};


const CancelledBinList: React.FC<ICancelledBinListProps> = ({
  bins = [],
  backUrl = '/dashboard',
}) => {
  const navigate = useNavigate();

  function handleBack() {
    if (backUrl) {
      navigate(backUrl);
    }
  }

  function handleOk(bin) {
    navigate(`../${bin?.name}`)
    }

  return (
  <div className="w-full space-y-8">
    {backUrl ? (
        <header className="flex text-xl font-bold border-b">
          <Button
            size="custom"
            className="w-8 h-8 mr-1"
            variant="clear"
            color="light-gray"
            onClick={handleBack}
          >
            <ChevronLeft size={26} />
          </Button>
          <h2 className="uppercase text-mantis-500">Cancelled Bins</h2>
        </header>
      ) : null}
      <div className="list-wrapper">
      <ul className="grid gap-8">
          {bins.map((bin) => (
              <li key={bin._id} 
              >
                <Bin 
                id={bin._id}
                name={bin.name}
                onOk={() => handleOk(bin)}
                 />
              </li>
            ))}
            {
              bins.length === 0 && <li>There is no more cancelled Bins</li> 
            }
        </ul>
      </div>
  </div>);
};

export default CancelledBinList;
