import { useToggle } from 'hooks/util/useToggle';
import React, { useRef, useState } from 'react';
import { Menu, Package, Trash2, XOctagon, CheckCircle } from 'react-feather';
import Button from 'ui-components/Button';
import Drawer from 'ui-components/Drawer';
import Modal from 'ui-components/Modal';
import BinScanner from './BinScanner';
import SidebarMenu from './SidebarMenu';

interface IHamburgerMenuProps {}

const HamburgerMenu: React.FC<IHamburgerMenuProps> = () => {
  const [menuOpen, toggleMenu] = useToggle();
  const [removeEntryModalOpen, setRemoveEntryModalOpen] = useState(false);
  const binInputRef = useRef();
  const removalTriggerRef = useRef({ handleRemovalTrigger: () => null });

  return (
    <div className="inline-flex">
      <Button
        onClick={toggleMenu}
        className="p-2 rounded-lg"
        size="custom"
        variant="clear"
        color="light-gray"
      >
        <Menu size="32" />
      </Button>

      <Drawer
        className="flex-none w-8/12 bg-white shadow-xl"
        open={menuOpen}
        onClose={toggleMenu}
      >
        <SidebarMenu
          links={[
            {
              icon: <Package />,
              label: 'Bins',
              to: '/dashboard/bins',
            },
            {
              icon: <Trash2 />,
              label: 'Remove Entry',
              onClick: () => setRemoveEntryModalOpen(true),
            },
            {
              icon: <XOctagon />,
              label: 'Cancelled Bins',
              to: '/dashboard/bins/cancelled',
            },
            {
              icon: <CheckCircle />,
              label: 'Complete Batch',
              to: '/dashboard/batches',
            },
          ]}
          onClose={toggleMenu}
        />
      </Drawer>
      <Modal
        open={removeEntryModalOpen}
        onClose={() => setRemoveEntryModalOpen(false)}
        title={`Choose a Bin`}
        okLabel="Go"
        cancelLabel="Cancel"
        onOk={() => {
          setRemoveEntryModalOpen(false);
          toggleMenu();
          removalTriggerRef.current.handleRemovalTrigger();
        }}
      >
        <BinScanner
          isScanning={true}
          binInputRef={binInputRef}
          embedded={true}
          removalTriggerRef={removalTriggerRef}
        />
      </Modal>
    </div>
  );
};
export default HamburgerMenu;
