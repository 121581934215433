import { AuthProvider } from 'contexts/AuthContext';
import AppRoute from 'routes/AppRoute';
import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function App() {
  const location = useLocation();

  useEffect(() => {
    if ((window as any).swUpdateReady) {
      (window as any).swUpdateReady = false;
      window.stop();
      window.location.reload();
    }
  }, [location]);

  return (
    <>
      <AuthProvider>
        <AppRoute />
      </AuthProvider>
      <Toaster />
    </>
  );
}

export default App;
