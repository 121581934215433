import React from 'react';

interface IScanAreaIconProps {
  className?: string;
}

const ScanAreaIcon: React.FC<IScanAreaIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      version="1.1"
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="currentColor"
      className={className}
    >
      <path d="M0 2a2 2 0 012-2h4v2H2v4H0V2m24 20a2 2 0 01-2 2h-4v-2h4v-4h2v4M2 24a2 2 0 01-2-2v-4h2v4h4v2H2M22 0a2 2 0 012 2v4h-2V2h-4V0h4z"></path>
    </svg>
  );
};
export default ScanAreaIcon;
