import CancelledBinList from 'components/CancelledBinList';
import useBinData from 'hooks/data/useBinData';
import { useAppLayoutOutletContext } from 'layouts/AppLayout';
import React from 'react';

type CancelledBinsPageProps = {};

const CancelledBinsPage: React.FC<CancelledBinsPageProps> = () => {
  const { setState: setAppLayoutState } = useAppLayoutOutletContext();
  const { cancelledBins, fetchCancelledBins } = useBinData('');

  React.useEffect(() => {
    fetchCancelledBins();
    const footer = (
      <div className="grid mt-2 place-items-center">
        Please remove any product from these bins before clearing. 
      </div>
    );
    setAppLayoutState({
      footer,
      subTitle: 'Bins containing cancelled Orders',
    });
  }, [setAppLayoutState, fetchCancelledBins]);

  return(
    <CancelledBinList
      bins={cancelledBins}
     />
  );
};

export default CancelledBinsPage;
