import classNames from 'classnames';
import React from 'react';
import { AlertTriangle } from 'react-feather';
import Button from 'ui-components/Button';
import Modal from 'ui-components/Modal';

interface IClearBinModalProps {
  type?: React.ReactNode;
  title?: React.ReactNode;
  message?: React.ReactNode;
  open?: boolean;
  closeBtnLabel: React.ReactNode;
  okLabel?: React.ReactNode;
  onClose?: (e?: any) => void;
  onOk?: (e?: any) => void;
};

const ClearBinModal: React.FC<IClearBinModalProps> = ({
  type,
  title,
  message,
  open = false,
  onClose,
  onOk,
  okLabel,
}) => {

  const Icon = AlertTriangle;

  return (
    <Modal 
        paddingClassName="p-0" 
        open={open} 
        onClose={onClose}
        footer={null}
        >
      <div className="px-8 pb-8">
        <div className="relative flex flex-col justify-center pt-10 text-center">
          <div className="mx-auto mb-4">
            <Icon
               className={classNames('stroke-[1px]', {
                'text-emerald-500': type === 'success',
                'text-rose-600': type === 'error',
              })}
              height="80"
              width="80"
            />
          </div>
          <div className={'text-2xl font-semibold'}>{title}</div>
          <div className="my-6 text-lg">{message}</div>
          <div className="flex-none w-9/12 mx-auto">
            <Button
              color="custom"
              className={classNames('w-full text-white', {
                'bg-emerald-600': type === 'success',
                'bg-rose-600': type === 'error',
              })}
              onClick={onOk}
            >
              {okLabel}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ClearBinModal;