import classNames from 'classnames';
import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';

interface ISidebarLinkItemProps {
  isActive?: boolean;
  icon?: React.ReactNode;
  label: string;
  hideChevron?: boolean;
  onClick?: () => void;
}

const SidebarLinkItem: React.FC<ISidebarLinkItemProps> = ({
  isActive,
  label,
  icon,
  hideChevron,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames('flex items-center px-6 py-4 space-x-3', {
        'bg-mantis-100': isActive,
      })}
    >
      <div
        className={classNames('flex-none w-8', {
          'text-gray-300': !isActive,
          'text-mantis-500': isActive,
        })}
      >
        {icon}
      </div>
      <div className="flex-grow font-semibold text-gray-900">{label}</div>
      <div hidden={hideChevron} className="flex-none w-8 text-right">
        {(isActive && (
          <ChevronLeft size="22" className="text-mantis-500" />
        )) || <ChevronRight size="22" className="text-gray-300" />}
      </div>
    </div>
  );
};
export default SidebarLinkItem;
