import { useAuth } from "contexts/AuthContext";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Spinner from "ui-components/Spinner";

interface IPrivatePageProps {
  children: JSX.Element;
}

const PrivatePage: React.FC<IPrivatePageProps> = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (auth.isAuthenticated === undefined) {
    return <Spinner fullScreen className="text-5xl" />;
  }
  if (auth.isAuthenticated === false) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
export default PrivatePage;
