import classNames from 'classnames';
import React from 'react';
import { Check, Minus } from 'react-feather';

interface IBinItemProps {
  id?: string;
  name: string;
  binned?: boolean;
  selected?: boolean;
  onSelect?: (item) => void;
}

const BinItem: React.FC<IBinItemProps> = ({
  id,
  name,
  binned,
  selected,
  onSelect = console.info,
}) => {
  return (
    <div
      data-id={id}
      className={classNames('flex space-x-3 items-center')}
    >
      <span className="flex-grow">{name}</span>
      <div
        className={classNames(
          'text-white flex-none w-8 h-8 rounded-full grid place-items-center',
          {
            'bg-gray-600': !binned && !selected,
            'bg-mantis-500': binned,
            'bg-blue-500': selected,
          }
        )}
      >
        {(binned || selected) && <Check size={26} />}

        {!selected && !binned && <Minus size={26} />}
      </div>
    </div>
  );
};
export default BinItem;
