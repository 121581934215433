import classNames from 'classnames';
import React from 'react';
import { X } from 'react-feather';
import Portal from 'ui-components/Portal';
import Button, { IButtonProps } from './Button';

interface ModalProps {
  title?: React.ReactNode;
  open?: boolean;
  footer?: boolean | React.ReactNode;
  zIndexClassName?: string;
  widthClassName?: string;
  paddingClassName?: string;
  okLabel?: string;
  cancelLabel?: string;
  okBtnProps?: IButtonProps;
  onClose?: (event: any) => void;
  onCancel?: (e?: any) => void;
  onOk?: (e?: any) => void;
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  footer = true,
  zIndexClassName = 'z-30',
  widthClassName = 'w-full lg:w-5/12',
  paddingClassName = 'p-5',
  children,
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  onOk,
  onCancel,
  okBtnProps,
}) => {
  const [bodyOverflowHidden, setBodyOverflowHidden] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const body = document.querySelector('body')!;
    const hasBodyOverflowHiddenClass =
      body.classList.contains('overflow-hidden');

    if (open && !hasBodyOverflowHiddenClass && !bodyOverflowHidden) {
      body.classList.add('overflow-hidden');
      setBodyOverflowHidden(true);

      return () => {
        if (!open && bodyOverflowHidden) {
          body.classList.remove('overflow-hidden');
        }
      };
    }
    if (!open && hasBodyOverflowHiddenClass && bodyOverflowHidden) {
      setBodyOverflowHidden(false);
      body.classList.remove('overflow-hidden');
    }
  }, [open, bodyOverflowHidden]);

  if (!onCancel) {
    onCancel = onClose;
  }
  if (!open) {
    return null;
  }
  return (
    <Portal>
      <div
        className={classNames(
          'fixed top-0 left-0 w-full grid place-items-center h-screen max-h-screen overflow-hidden px-5',
          zIndexClassName
        )}
      >
        <div className="fixed inset-0 bg-slate-500/70" onClick={onClose} />
        <ModalContainer
          paddingClassName={paddingClassName}
          widthClassName={widthClassName}
        >
          <ModalHeader title={title} onClose={onClose} />

          <ModalBody>{children}</ModalBody>

          {(typeof footer === 'boolean' && (
            <ModalFooter
              okLabel={okLabel}
              cancelLabel={cancelLabel}
              onOk={onOk}
              onCancel={onCancel}
              okBtnProps={okBtnProps}
            />
          )) ||
            footer}
        </ModalContainer>
      </div>
    </Portal>
  );
};
export default Modal;

function ModalContainer({ children, widthClassName, paddingClassName }: any) {
  return (
    <div
      className={classNames(
        'relative shadow-xl bg-neutral-50 rounded-2xl grid gap-5',
        widthClassName,
        paddingClassName
      )}
    >
      {children}
    </div>
  );
}

function ModalHeader({ title, onClose }: any) {
  return (
    <>
      {!!title && (
        <header className="relative flex justify-between">
          {!!title && <div className="text-lg font-medium">{title}</div>}
        </header>
      )}
      <button
        className="absolute top-0 right-0 z-10 grid w-6 h-6 transform -translate-x-1 translate-y-1 rounded-full bg-neutral-200 place-items-center"
        onClick={onClose}
      >
        <X className="w-4" />
      </button>
    </>
  );
}

function ModalBody({ children }: any) {
  return (
    <div className="max-h-[calc(100vh-15rem)] overflow-y-auto overscroll-y-contain">
      {children}
    </div>
  );
}

function ModalFooter({
  onCancel,
  cancelLabel,
  okLabel,
  onOk,
  okBtnProps,
}: any) {
  return (
    <footer className="flex items-center justify-end space-x-4">
      <Button color="light-gray" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button onClick={onOk} {...okBtnProps}>
        {okLabel}
      </Button>
    </footer>
  );
}
