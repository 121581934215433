import React, { useCallback, useState } from 'react';
import { useClearableState } from './useClearableState';

export function useStateWithInputHandler<T>(initialValue: T): [
  T,
  (e: React.ChangeEvent<HTMLInputElement>) => void,
  (value: T) => void
  // () => void
] {
  const [state, setState] = useState(initialValue);
  const [result, setResult, clearResult] = useClearableState({
    bin: '',
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, type } = event.currentTarget;
      let newValue: any = value;
      if (['checkbox'].includes(type)) {
        const checked = (event.currentTarget as any).checked;
        newValue = checked;
      }
      let newState: any = (typeof state === 'string' && newValue) || {
        ...state,
        [name]: newValue,
      };

      setState(newState);
    },
    [state]
  );

  // const resetState = useCallback(() => {
  //   setState(initialValue);
  // }, []);

  return [
    state,
    handleChange,
    setState,
    // resetState
  ];
}
