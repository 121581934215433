import BinItemsList from 'components/BinItemsList';
import useBinData from 'hooks/data/useBinData';
import { useAppLayoutOutletContext } from 'layouts/AppLayout';
import { includes, without } from 'ramda';
import React, { useCallback, useReducer, useState } from 'react';
import { Check } from 'react-feather';
import { useParams } from 'react-router-dom';
import { CLEAR_UNBINNED_ACTION, UNBIN_ITEM_ACTION } from 'store/actions/BinActions';
import BinReducer from 'store/reducers/BinReducer';
import Modal from 'ui-components/Modal';

const BinRemovePage: React.FC<any> = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const [state, dispatch] = useReducer(BinReducer, { unbinned: [] });
  const [removalConfirmModal, setRemovalConfirmModal] = useState(false);
  const { setState: setHeaderAndFooter } = useAppLayoutOutletContext();
  const { bin, removeItemFromBin, fetchBin } = useBinData(
    id || ''
  );
  const items = bin?.order.items;
  const handleSelect = useCallback((item) => {
    if (state?.unbinned) {
      let _unbinned = Object.assign(state.unbinned);

      if (!includes(item.id, _unbinned)) {
        _unbinned.push(item.id);
      } else {
        _unbinned = without([item.id], _unbinned);
      }

      dispatch({ type: UNBIN_ITEM_ACTION.type, payload: _unbinned });
    }
  }, [state?.unbinned]);

  React.useEffect(() => {
    const title = (
      <span className="block font-bold text-center text-gray-600">
        Remove Entries for Bin #{id} / Order #{bin?.orderNumber}
      </span>
    );
    const footer = (
      <div className="flex flex-col items-center mt-3 space-y-3">
        {state?.unbinned && state?.unbinned.length > 0 ? (<><button
          onClick={() => setRemovalConfirmModal(true)}
          className="flex items-center justify-center w-16 h-16 text-2xl text-center text-white bg-blue-500 rounded-full"
        >
          <Check size={40} />
        </button>
          <span className="text-sm font-bold text-gray-600">
            Confirm Removal
          </span></>) : (<></>)}
      </div>
    );
    setHeaderAndFooter({
      title,
      footer,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeaderAndFooter, bin, state?.unbinned, setRemovalConfirmModal]);

  return <div>
    <BinItemsList
      binId={id}
      items={items}
      filterBinned={false}
      filterUnbinned={true}
      onSelect={handleSelect}
      selectedItems={state?.unbinned}
    />
    <Modal
      open={removalConfirmModal}
      onClose={() => setRemovalConfirmModal(false)}
      title={`Are you sure you want to unbin these items?`}
      okLabel="Remove"
      cancelLabel="Cancel"
      onOk={() => {
        state?.unbinned.forEach(_id => {
          const item = items?.filter(_item => _item._id === _id)[0];

          if (item) {
            removeItemFromBin(item);
          }
        })

        fetchBin(id);
        setRemovalConfirmModal(false);
        dispatch({ type: CLEAR_UNBINNED_ACTION.type});
      }}
    ></Modal>
  </div>;
};

export default BinRemovePage;
