import React from 'react';
import { IUser } from 'types/user';

interface IMenuAvatarSectionProps {
  user: IUser;
  className?: string;
}

const MenuAvatarSection: React.FC<IMenuAvatarSectionProps> = ({
  user,
  className,
}) => {
  return (
    <div className="flex flex-col items-center">
      {!!user.profilePhoto && (
        <img
          src={user.profilePhoto}
          alt=""
          className="w-16 h-16 mb-4 rounded-full"
          loading="lazy"
        />
      )}
      {!user.profilePhoto && user.firstName && user.lastName && (
        <div className="grid w-16 h-16 mb-4 text-xl font-bold text-white rounded-full place-items-center bg-mantis-500">
          <span>
            {user.firstName[0]}
            {user.lastName[0]}
          </span>
        </div>
      )}
      <p className="text-lg font-bold text-gray-900 font-secondary">
        {user.firstName}
      </p>
      <p className="text-gray-400">{user.email}</p>
    </div>
  );
};
export default MenuAvatarSection;
