export const DEFAULT_ROUTE = '/dashboard';
export const API_URL = process.env.REACT_APP_API_URL;
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const BINSTATUS = {
    EMPTY: 'EMPTY',
    HOLD: 'HOLD',
    FLAGGED: 'FLAGGED',
    PARTIAL: 'PARTIAL',
    COMPLETE: 'COMPLETE',
    CANCELLED: 'CANCELLED',
  };
