import AppLayout from 'layouts/AppLayout';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BinsRoutes from './BinsRoutes';
import BatchesRoutes from './BatchesRoutes';

interface IDashboardRoutesProps {}

const DashboardRoutes: React.FC<IDashboardRoutesProps> = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/" element={<Navigate to="bins" />} />
        <Route path="bins/*" element={<BinsRoutes />} />
        <Route path="batches/*" element={<BatchesRoutes />} />
      </Route>
    </Routes>
  );
};
export default DashboardRoutes;
