import React from "react";

interface ILoginPageSkeletonProps {
  headingSection?: React.ReactNode;
  loginFormSection?: React.ReactNode;
  footerSection?: React.ReactNode;
}

const LoginPageSkeleton: React.FC<ILoginPageSkeletonProps> = ({
  headingSection,
  loginFormSection,
  footerSection,
}) => {
  return (
    <div className="h-full w-full flex flex-col p-12">
      <section className="flex-grow flex flex-col items-stretch justify-center">
        <header className="mb-8">{headingSection}</header>
        <section>{loginFormSection}</section>
      </section>
      <section className="flex-none">{footerSection}</section>
    </div>
  );
};
export default LoginPageSkeleton;
