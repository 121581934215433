import BinDetailsPage from 'pages/BinDetailsPage';
import BinItemsPage from 'pages/BinItemsPage';
import BinRemovePage from 'pages/BinRemovePage';
import BinsPage from 'pages/BinsPage';
import CancelledBinsPage from 'pages/CancelledBinsPage';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

interface IBinsRoutesProps {}

const BinsRoutes: React.FC<IBinsRoutesProps> = () => {
  return (
    <Routes>
      <Route index element={<BinsPage />} />
      <Route path="remove/:id" element={<BinRemovePage />} />
      <Route path=":id" element={<Navigate to="all" />} />
      <Route path=":id/:activeTab" element={<BinDetailsPage />} />
      <Route path="items/:id" element={<BinItemsPage />} />
      <Route path="/cancelled" element={<CancelledBinsPage />} />
    </Routes>
  );
};
export default BinsRoutes;
