import classNames from 'classnames';
import React from 'react';

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  ref?: React.RefObject<any> | any;
}

const Input: React.FC<IInputProps> = React.forwardRef((props, ref = null) => {
  return (
    <label className="relative">
      <input
        ref={ref}
        {...props}
        className="block w-full border-b-2 rounded-none appearance-none focus:border-mantis-500 focus:outline-none h-11 peer"
      ></input>
      <span
        className={classNames(
          'text-sm absolute bottom-full transform translate-y-2 font-semibold text-mantis-500 transition-all duration-200 ease-out',
          'peer-focus:translate-y-2 peer-focus:font-semibold peer-focus:text-mantis-500',
          'peer-placeholder-shown:translate-y-8 peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-400'
        )}
      >
        {props.label}
      </span>
    </label>
  );
});

export default Input;
