import { useCallback } from 'react';
import ModalAlert from 'ui-components/ModalAlert';
import { useClearableState } from './useClearableState';
import { useToggle } from './useToggle';

interface IUseSuccessErrorModalProps {
  onSuccessClose?: () => void;
  onErrorClose?: () => void;
}
export default function useSuccessErrorModal(
  props?: IUseSuccessErrorModalProps
) {
  const [loading, toggleLoading] = useToggle();
  const [succesMsg, setSuccessMsg, clearSuccessMsg] = useClearableState('');
  const [errMsg, setErrMsg, clearErrMsg] = useClearableState('');

  const handleClose = useCallback(() => {
    const isSuccess = !!succesMsg;
    if (isSuccess) {
      clearSuccessMsg();
      props?.onSuccessClose?.();
      return;
    }
    clearErrMsg();
    props?.onErrorClose?.();
  }, [succesMsg, props, clearSuccessMsg, clearErrMsg]);

  const SuccessErrModal = (
    <ModalAlert
      open={!!succesMsg || !!errMsg}
      onClose={handleClose}
      type={(!!succesMsg && 'success') || 'error'}
      title={(!!succesMsg && 'Success') || 'Error'}
      message={succesMsg || errMsg}
    />
  );

  return {
    loading,
    toggleLoading,
    setSuccessMsg,
    setErrMsg,
    SuccessErrModal,
  };
}
