import { CLEAR_UNBINNED_ACTION, UNBIN_ITEM_ACTION } from "store/actions/BinActions";

export default function BinReducer(state, action) {
  if (action.type === UNBIN_ITEM_ACTION.type) {
    return {
      unbinned: [
        ...action.payload
      ]
    };
  }

  if (action.type === CLEAR_UNBINNED_ACTION.type) {
    return {
      unbinned: []
    };
  }
}