import QrScanIcon from 'customIcons/QrScanIcon';
import useSuccessErrorModal from 'hooks/util/useSuccessErrorModal';
import { useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from 'ui-components/Input';

type BinScannerProps = {
  isScanning: boolean;
  embedded?: boolean;
  binInputRef: React.MutableRefObject<any>;
  removalTriggerRef?: React.MutableRefObject<any>;
  onScanned?: (value: string) => void;
};

const BinScanner: React.FC<BinScannerProps> = ({
  isScanning,
  embedded = false,
  binInputRef,
  removalTriggerRef,
  onScanned,
}) => {
  const { SuccessErrModal, setErrMsg } = useSuccessErrorModal();
  const [binInput, setBinInput] = useState('');
  const navigate = useNavigate();
  const handleRemovalTrigger = () => {
    navigate(`/dashboard/bins/remove/${binInput}`);
  };

  useImperativeHandle(
    removalTriggerRef,
    () => ({
      handleRemovalTrigger,
    }),
    [binInput]
  );

  function handleScan(value: string) {
    if (value) {
      if (!onScanned) {
        setBinInput('');
        navigate(`./${value}/unbinned`);
      } else {
        onScanned(value);
      }
    }
  }

  return (
    <div className="my-auto">
      <div className="relative flex justify-center">
        <Input
          name="qr-scanner-input"
          value={binInput}
          ref={binInputRef}
          autoFocus={true}
          autoComplete="off"
          autoCorrect="off"
          onChange={(ev) => setBinInput(ev.target.value)}
          style={{
            textAlign: 'center',
            visibility: isScanning ? 'visible' : 'hidden',
          }}
          onKeyDown={(ev) => {
            if (!embedded && ev.key === 'Enter') {
              const binNumber = String(ev?.currentTarget.value).trim() || null;

              if (binNumber) {
                handleScan(binNumber);
              } else {
                setErrMsg(
                  `Something went wrong. Input is: ${ev?.currentTarget.value}`
                );
              }
            }
          }}
        />
      </div>
      {(!embedded && (
        <div className="relative flex-none w-full min-h-[20vh]">
          <div className="absolute inset-0 grid place-items-center">
            <QrScanIcon className="text-[8rem] text-gray-600 stroke-transparent" />
          </div>
        </div>
      )) || <></>}
      {SuccessErrModal}
    </div>
  );
};

export default BinScanner;
