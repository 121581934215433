import classNames from 'classnames';
import React from 'react';
import Portal from './Portal';

interface IDrawerProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  direction?: 'left' | 'bottom';
  hideBackdrop?: boolean;
  backdropClassName?: string;
  open?: boolean;
  onClose?: () => void;
}

const Drawer: React.FC<IDrawerProps> = ({
  open,
  onClose,
  hideBackdrop,
  className,
  direction = 'left',
  children,
  backdropClassName,
  ...props
}) => {
  return (
    <Portal>
      {open && !hideBackdrop && (
        <div
          className={classNames(
            'fixed inset-0 bg-slate-500/70',
            backdropClassName
          )}
          onClick={onClose}
        />
      )}
      <div
        {...props}
        className={classNames(
          className,
          'fixed  transform transition-transform duration-200 ease-out',
          {
            'left-0': direction === 'left',
            // 'right-0': direction === 'right',
            '-translate-x-[110%]': !open && direction === 'left',
            // 'translate-x-[110%]': !open && direction === 'right',
            'translate-y-[110%]': !open && direction === 'bottom',
            'h-full top-0': direction === 'left',
            'w-full bottom-0': direction === 'bottom',
          }
        )}
      >
        {children}
      </div>
    </Portal>
  );
};
export default Drawer;
