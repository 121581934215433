import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IBin } from 'types/bin';
import { IHttpResponse } from 'types/httpResponse';
import { IProduct } from 'types/product';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';

export default function useBinData(id: string) {
  const [bin, setBin] = useState<IBin>();
  const [cancelledBins, setCancelledBins] = useState<IBin[]>();
  const [itemsToBin, setItemsToBin] = useState<string[]>([]);
  const [binErrMsg, setBinErrmsg] = useState<string>('');
  const [cancelledBinsErrmsg, setCancelledBinsErrmsg] = useState<string>('');
  const [binStatus, setBinStatus] = useState<String>('');

  const { get, patch } = useFetch();
  const navigate = useNavigate();

  const fetchBin = useCallback(
    async (id) => {
      const url = `/bins/name/${id}/0`;
      const binRes: IHttpResponse<IBin> = await get(url);
      if (binRes.error) {
        setBinErrmsg(binRes.error.message);
        return;
      }
      const bin = binRes.data;
      const status = bin.status;
      setBin(bin);
      setBinStatus(status);
    },
    [get]
  );

  const fetchCancelledBins = useCallback(
    async () => {
      const url = `/bins/cancelled`;
      const binRes: IHttpResponse<IBin[]> = await get(url);
      if (binRes.error) {
        setCancelledBinsErrmsg(binRes.error.message);
        return;
      }
      const bins = binRes?.data;
      setCancelledBins(bins);
    },
    [get]
  )

  useEffect(() => {
    if (id) {
      // api to fetch bin, and items in the bin
      fetchBin(id);
    }
  }, [fetchBin, id]);

  function updateItemsToBinList(id: string) {
    let newItemsToBin: string[] = [...itemsToBin];
    if (itemsToBin.includes(id)) {
      const idx = itemsToBin.indexOf(id);
      newItemsToBin.splice(idx, 1);
    } else {
      newItemsToBin.push(id);
    }
    setItemsToBin(newItemsToBin);
  }

  async function addItemToBin(item: IProduct) {
    const url = `/items/${item._id}/bin`;
    const res: IHttpResponse<boolean> = await patch(url, { binId: bin!._id });
    const isBinned = res.data;

    if (res.error) {
      toast.error(res.error.message);
      return;
    }

    if (isBinned) {
      toast.success('Item binned');
      const newItem = { ...item, bin: { status: true } };
      const newItems = bin!.order.items.map((itm) => {
        if (itm._id === item._id) {
          return newItem;
        }
        return itm;
      });
      const newBin = {
        ...bin!,
        order: {
          ...bin!.order,
          items: newItems,
        },
      };

      fetchBin(id);
      setBin(newBin);
    }
  }

  async function removeItemFromBin(item: IProduct) {
    const url = `/items/${item._id}/unbin`;
    const res: IHttpResponse<boolean> = await patch(url, { binId: bin!._id });
    const isBinned = res.data;

    if (res.error) {
      toast.error(res.error.message);
      return;
    }

    if (!isBinned) {
      toast.success('Item unbinned');
      const newItem = { ...item, bin: { status: false } };
      const newItems = bin!.order.items.map((itm) => {
        if (itm._id === item._id) {
          return newItem;
        }
        return itm;
      });
      const newBin = {
        ...bin!,
        order: {
          ...bin!.order,
          items: newItems,
        },
      };

      fetchBin(id);
      setBin(newBin);
    }
  }

  async function clearBin(id: string, redirectUrl: string) {
    const url = `/bins/${id}/clear`;
    const reason = `Order Cancelled`;
    const res: IHttpResponse<boolean> = await patch(url, { binId: id, reason });
    const isCleared = res?.data;

    if (res.error) {
      toast.error(res.error.message);
      return;
    }

    if (isCleared) {
      toast.success('Thank you, the bin has been cleared !');
      navigate(redirectUrl);
      return;
    }
  }

  return {
    bin,
    itemsToBin,
    updateItemsToBinList,
    addItemToBin,
    removeItemFromBin,
    binErrMsg,
    binStatus,
    fetchBin,
    clearBin,
    fetchCancelledBins,
    cancelledBins,
    cancelledBinsErrmsg,
  };
}
